import React from "react";
import { allLeadByTypeUrl } from "../Utils/Queries/lead";
import { SectionLoader } from "../Uikits/Others";
import { useParams } from "react-router-dom";
import { needAuth } from "../Hocs/NeedAuth";
import useCustomQuery from "../Hook/query";
import axiosInstance from "../Utils/Axios";
import { errorToast, successToast } from "../Utils/Toast";

function ManageLead() {
  const { leadType } = useParams();
  const { isLoading, error, data, refetch } = useCustomQuery(
    allLeadByTypeUrl + leadType
  );

  function blockLead(leadId) {
    axiosInstance
      .put("/lead/block/" + leadId)
      .then((res) => successToast("Email bloquer"))
      .catch((err) => errorToast("Une erreur survenue"))
      .finally(() => {
        refetch();
      });
  }

  function deleteLead(leadId) {
    axiosInstance
      .delete("/lead/delete/" + leadId)
      .then((res) => successToast("Email supprimer"))
      .catch((err) => errorToast("Une erreur survenue"))
      .finally(() => {
        refetch();
      });
  }

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>
        Gestion des email de la page{" "}
        {leadType == "product" ? "produit" : "newletter"} ({data.data.length}{" "}
        emails){" "}
      </h1>
      {data.data.length > 0 ? (
        <div className="dm-dataList flex f-wrap">
          {data.data.map((item, i) => (
            <article key={"product lead nb" + i}>
              <p>{item.email} </p>
              <a href={"mailto:" + item.email} className="updateBtn">
                Contacter par mail
              </a>
              <div style={{ color: "var(--textColor)" }}>
                {!item.blocked && (
                  <button onClick={() => blockLead(item._id)}>Bloquer</button>
                )}
                <button onClick={() => deleteLead(item._id)}>Supprimer</button>
              </div>
            </article>
          ))}
        </div>
      ) : (
        <p>Aucun lead disponible</p>
      )}
    </div>
  );
}

export default needAuth(ManageLead);
