"use client";
import { useField } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { FormikErrorMessage } from ".";
import axiosInstance from "../../Utils/Axios";
import { errorToast } from "../../Utils/Toast";
import { SectionLoader } from "../Others";

export function FormikSelect(props) {
  const { name, label, valueKey, labelKey, chooseLabelKey, options } = props;
  const [toggleSelection, setToggleSelection] = useState(false);
  const [field, meta, helpers] = useField(name);
  const [currentValueLabel, setCVL] = useState(false);
  const { value } = meta;

  useEffect(() => {
    if (value) {
      const filt = options.filter((item) => item[valueKey] == value);

      if (filt && filt.length > 0) {
        setCVL(filt[0][labelKey]);
      }
    }
  }, [value]);

  return (
    <div className={"formField formFieldSelect"}>
      <label htmlFor="">{label}</label>
      <b onClick={() => setToggleSelection((prev) => !prev)} className="flex">
        {currentValueLabel ? currentValueLabel : chooseLabelKey}
      </b>
      <section className="flex f-column">
        {toggleSelection && (
          <>
            {options.map((options, i) => (
              <span
                key={"select " + name + " nb" + i}
                onClick={() => {
                  helpers.setValue(options[valueKey]);
                  setToggleSelection(false);
                }}
                className={options[valueKey] == value ? "currentSelected" : ""}
              >
                {options[labelKey]}
              </span>
            ))}
          </>
        )}
      </section>
      <FormikErrorMessage name={name} />
    </div>
  );
}

export function FormikSelectWithApi(props) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    name,
    label,
    valueKey,
    labelKey,
    chooseLabelKey,
    apiUrl,
    firstOption,
  } = props;
  const [toggleSelection, setToggleSelection] = useState(false);
  const [field, meta, helpers] = useField(name);
  const [currentValueLabel, setCVL] = useState(false);
  const { value } = meta;

  useEffect(() => {
    if (value) {
      const filt = options.filter((item) => item[valueKey] == value);

      if (filt && filt.length > 0) {
        setCVL(filt[0][labelKey]);
      }
    }
  }, [value, options]);

  useEffect(() => {
    axiosInstance
      .get(apiUrl)
      .then((res) =>
        setOptions(firstOption ? [firstOption, ...res.data] : res.data)
      )
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <SectionLoader />;
  return (
    <div className={"formField formFieldSelect"}>
      <label htmlFor="">{label}</label>
      <b onClick={() => setToggleSelection((prev) => !prev)} className="flex">
        {currentValueLabel ? currentValueLabel : chooseLabelKey}
      </b>
      <section className="flex f-column">
        {toggleSelection && (
          <>
            {options.map((options, i) => (
              <span
                key={"select " + name + " nb" + i}
                onClick={() => {
                  helpers.setValue(options[valueKey]);
                  setToggleSelection(false);
                }}
                className={options[valueKey] == value ? "currentSelected" : ""}
              >
                {options[labelKey]}
              </span>
            ))}
          </>
        )}
      </section>
      <FormikErrorMessage name={name} />
    </div>
  );
}
