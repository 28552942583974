import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MdiInstagram,
  MdiWhiteBalanceSunny,
  PhMoonFill,
  MdiMenu,
  MdiTelegram,
  MdiWhatsapp,
} from "./Icons";
import useThemeStore from "../Stores/Theme";

export function ToggleThemeBtn({ forSidebar }) {
  const theme = useThemeStore((state) => state.theme);
  const toggleTheme = useThemeStore((state) => state.toggleTheme);
  return (
    <span
      onClick={() => toggleTheme(theme)}
      className={forSidebar ? "themeTogglerForSidebar" : ""}
    >
      {theme == "light" ? <PhMoonFill /> : <MdiWhiteBalanceSunny />}{" "}
    </span>
  );
}
function NavLink() {
  return (
    <section className="navLinks">
      <Link to={"/"}>Accueil</Link>
      <Link to={"/about"}>A-propos</Link>
      <Link to={"/faq"}>FAQ</Link>
      <Link to={"/contact"}>Contact</Link>
      <Link to={"/products"}>Produits</Link>
      <Link to={"/testimonials"}>Témoignages</Link>
      <ToggleThemeBtn />
    </section>
  );
}

function Nav() {
  const [hamburgerMenuVisible, setHMV] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setHMV(false);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Position verticale actuelle du scroll
      const navbarHeight = document.querySelector("nav").offsetHeight; // Hauteur de la barre de navigation

      if (scrollPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const currentTheme = localStorage.getItem("theme") || "light";
    document.documentElement.setAttribute("data-theme", currentTheme);
    window.addEventListener("scroll", handleScroll);

    // Nettoyage de l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={isScrolled ? " navScrolled " : ""}>
      <section className="navLogo">
        <img src="/images/logo.png" alt="nav logo" />
      </section>
      <NavLink />
      <section
        className="navHamburger"
        onClick={() => setHMV((prev) => !prev)}
        // onClick={() => alert("ok")}
      >
        <MdiMenu />{" "}
      </section>

      <div
        className={
          "navHamburgerContent flex f-column " +
          (hamburgerMenuVisible ? " navHamburgerContenVisible " : "")
        }
      >
        <NavLink />

        <section className="navSocials">
          <a
            href="https://www.instagram.com/mario_ckp/profilecard/?igsh=MTZreXNjbGwydGl5Zg=="
            target="_blank"
          >
            <MdiInstagram />
          </a>
          <a href="https://t.me/Mario0307" target="_blank">
            <MdiTelegram />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=%2B33632088663"
            target="_blank"
          >
            <MdiWhatsapp />
          </a>
        </section>
      </div>
      <section className="navSocials">
        <a
          href="https://www.instagram.com/mario_ckp/profilecard/?igsh=MTZreXNjbGwydGl5Zg=="
          target="_blank"
        >
          <MdiInstagram />
        </a>
        <a href="https://t.me/Mario0307" target="_blank">
          <MdiTelegram />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=%2B33632088663"
          target="_blank"
        >
          <MdiWhatsapp />
        </a>
      </section>
    </nav>
  );
}

export default Nav;
