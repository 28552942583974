import "./Styles/index.scss";
// import "./Styles/sun.css";
import "suneditor/dist/css/suneditor.min.css";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./Components/home/ScrollToTop";
import Modal from "./Uikits/Modal";
import { allRoutes } from "./Utils/Routes";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Modal />
      <Routes>
        {allRoutes.map((item, k) => (
          <Route key={"route nb" + k} path={item.path} element={item.element} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
