import React, { useState } from "react";

import ScrollAnimation from "react-animate-on-scroll";
import { handleNewLetterSubmit } from "../Uikits/Footer";
import { MdiArrowLeftThin, MdiArrowRightThin } from "../Uikits/Icons";

export function NewsLetter() {
  const [email, setEmail] = useState("");
  return (
    <ScrollAnimation animateIn="fadeInUp" className="newsLetter  flex">
      <section className="nl-text">
        <h4>Abonnez vous à notre newsletter</h4>
        <p>
          Entrez votre email adresse pour vous assurez que vous ne manquez
          aucune nouvelles concernant les offres juteux du weekend
        </p>
      </section>

      <section className="nl-input">
        <form action="" onSubmit={(e) => handleNewLetterSubmit(e, email)}>
          <input
            type="email"
            placeholder="Votre email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {email && <button>S’abonner </button>}
        </form>
      </section>
    </ScrollAnimation>
  );
}

export function NextSliderBtn() {
  return (
    <span>
      <MdiArrowRightThin />{" "}
    </span>
  );
}

export function PrevSliderBtn() {
  return (
    <span>
      <MdiArrowLeftThin />{" "}
    </span>
  );
}
