import React from "react";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import { AddorUpdateTrainerForm } from "../Components/training/trainer";
import useCustomQuery from "../Hook/query";
import { allCategoryUrl, allTrainerUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";
import { AOUCategory } from "../Components/category";
import { categoryOptions } from "../Config/formFields/others";

function ManageCategory() {
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(allCategoryUrl);

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>Gestion des categories</h1>
      <BorderButton
        text="Nouvelle categorie"
        onClick={() => showModal(<AOUCategory refetch={refetch} />)}
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"categorye nb " + i}>
              <p>
                <strong>Titre: </strong>
                {item.label}
              </p>
              <p>
                <strong>Type: </strong>
                {
                  categoryOptions.filter((cat) => cat.value == item.type)[0]
                    .label
                }
              </p>

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(<AOUCategory category={item} refetch={refetch} />)
                  }
                >
                  Modifier
                </button>

                {/* <button
                  className="deleteBtn"
                  onClick={() => deleteFormation(item._id)}
                >
                  Supprimer
                </button> */}
              </div>
            </article>
          ))
        ) : (
          <p>Aucune categorie disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageCategory);
