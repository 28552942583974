import axiosInstance from "../Axios";

export let allProductsByAdminUrl = "/product/all-for-admin";
export let allProductsUrl = "/product/all";
export async function getAllProducts() {
  let res = await axiosInstance.get("/product/all");
  return res;
}

export const oneProductUrl = "/product/";
export async function getOneProduct(productId) {
  let res = await axiosInstance.get("/product/" + productId);
  return res;
}
