import { adminRoutesObj } from "../../Utils/Others";

export const timeOptions = [
  { label: "Mois", value: "Mois" },
  { label: "An", value: "An" },
];

export const yesOrNoOptions = [
  { label: "Oui", value: "true" },
  { label: "Non", value: "false" },
];

export const categoryOptions = [
  { label: "Pour les produits", value: "product" },
  { label: "Pour les formations", value: "training" },
];

export const trainingLevelOptions = [
  { label: "Debutant", value: "beginer" },
  { label: "Intermediaire", value: "middle" },
  { label: "Avance", value: "advanced" },
];

export const targetApplicationOptions = [
  { label: "Ecomplane", value: "Ecomplane" },
  { label: "Adsplane", value: "Addplane" },
];

export const userGradeOptions = [
  { label: "Etudiant", value: "Student" },
  {
    label: "Un assistant au superAdmin",
    value: "Manager",
  },
];

export const userGradWithSuperAdminOptions = [
  { label: "Le SuperAdmin", value: "SuperAdmin" },
  ...userGradeOptions,
];

export const gradePermissionOptions = [
  adminRoutesObj.categories,
  adminRoutesObj.pricing,
  adminRoutesObj.reviews,
  // adminRoutesObj.leadsNewLetter,
  // adminRoutesObj.leadsProducts,
  adminRoutesObj.leads,
  adminRoutesObj.newletter,
  adminRoutesObj.products,
  adminRoutesObj.leadPageType,
  adminRoutesObj.trainers,
  adminRoutesObj.trainings,
  adminRoutesObj.trainingChapter,
  adminRoutesObj.trainingSubchapter,
  adminRoutesObj.trainingQuizz,
  adminRoutesObj.trainingQuizzResult,
];
