import React from "react";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import useCustomQuery from "../Hook/query";
import { allTrainingQuizzUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";
import { useParams } from "react-router-dom";
import { successToast } from "../Utils/Toast";
import axiosInstance from "../Utils/Axios";
import { AOUTrainingQuizzForm } from "../Components/training/quizz";

function ManageTrainingQuizz() {
  const { chapterId } = useParams();
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(
    allTrainingQuizzUrl + "/" + chapterId
  );

  function deleteQuizzQuestion(qId) {
    axiosInstance
      .delete("/trainingQuizz/delete/" + qId)
      .then((res) => successToast("Quizz supprimer !"))
      .catch((err) => null)
      .finally(() => {
        refetch();
      });
  }

  function makeQuizzAvailable(qId) {
    axiosInstance
      .put("/trainingQuizz/make-available-for-students/" + qId)
      .then((res) => successToast("Quizz disponible pour les eleves !"))
      .catch((err) => null)
      .finally(() => {
        refetch();
      });
  }

  if (isLoading) return <SectionLoader />;

  if (!data.data)
    return (
      <div className="dataManager">
        <p>Aucun quizz pour le moment</p>
        <BorderButton
          text="Nouvelle question au quizz"
          onClick={() =>
            showModal(
              <AOUTrainingQuizzForm chapterId={chapterId} refetch={refetch} />
            )
          }
        />
      </div>
    );
  return (
    <div className="dataManager">
      <h1>Gestion des quizz du chapitre</h1>
      <h2>
        Etat pour les etudiants:{" "}
        {data.data.availableForStudents ? "Disponible" : "Non disponible"}{" "}
      </h2>
      <div className="flex f-wrap jc-se ">
        <BorderButton
          text="Nouvelle question au quizz"
          onClick={() =>
            showModal(
              <AOUTrainingQuizzForm chapterId={chapterId} refetch={refetch} />
            )
          }
        />

        {data.data.questionsAndAnswers.length > 0 &&
          data.data.availableForStudents == false && (
            <BorderButton
              text="Rendre le quizz disponible"
              onClick={() => makeQuizzAvailable(data.data._id)}
            />
          )}
      </div>
      <section className="dm-dataList">
        {data.data.questionsAndAnswers.length > 0 ? (
          data.data.questionsAndAnswers.map((item, i) => (
            <article key={"quizz question nb " + i}>
              <p>
                <strong>Question: </strong>
                {item.question}
              </p>
              <p>
                <strong>Les reponses possibles</strong>
                <ul>
                  {item.suggestedAnswers.map((sa, j) => (
                    <li key={i + "sugs a nb" + j}>{sa} </li>
                  ))}
                </ul>
              </p>
              <p>
                <strong>La bonne reponse: </strong>
                {item.rightAnswer}
              </p>
              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AOUTrainingQuizzForm
                        chapterId={chapterId}
                        quizz={item}
                        refetch={refetch}
                      />
                    )
                  }
                >
                  Modifier
                </button>

                <button
                  className="deleteBtn"
                  onClick={() => deleteQuizzQuestion(item._id)}
                >
                  Supprimer
                </button>
              </div>
              {item.ressources && (
                <a
                  href={item.ressources.url}
                  download={item.ressources.filename}
                >
                  Telecharger les ressources
                </a>
              )}
            </article>
          ))
        ) : (
          <p>Aucune question de quizz disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageTrainingQuizz);
