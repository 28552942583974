import { Field, useField } from "formik";
import { FormikErrorMessage } from ".";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
export function FormikWYSIWGInput(props) {
  const { name, label, additionalOptions } = props;
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  function deleteByIndex(i) {
    const copy = [...value];
    copy.splice(i, 1);
    setValue(copy);
  }
  return (
    <div className="formField formFieldFile flex f-column">
      <label>{label}</label>

      <section>
        <SunEditor
          defaultValue={value}
          plugins={plugins}
          name={name}
          width="100%"
          height="auto"
          onChange={(content) => setValue(content)}
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle", "blockquote"],
              ["bold", "underline", "italic"],
              ...additionalOptions,
              ["removeFormat"],
              ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],

              ["fullScreen", "showBlocks"],
              ["preview"],
              ["save"],
            ],
          }}
        />
      </section>
      <FormikErrorMessage name={name} />
    </div>
  );
}

export function FormikDateTimeTextInput(props) {
  const { fieldType, label, name, ph, min, max } = props;
  return (
    <div className="formField">
      {label && <label htmlFor="">{label}</label>}
      <Field
        aria-label="Date and time"
        type={"datetime-local"}
        name={name}
        placeholder={ph}
        min={min}
        max={max}
      />
      <FormikErrorMessage name={name} />
    </div>
  );
}
