import { Form, FormikProvider, useFormik } from "formik";
import FormFieldProvider from "../Uikits/form";
import { FormButton } from "../Uikits/Button";
import { AOUCategoryValidation } from "../Config/formFields/fieldsValidations";
import axiosInstance from "../Utils/Axios";
import useModalStore from "../Stores/Modal";
import { errorToast, successToast } from "../Utils/Toast";
import { addOrUpdateCategoryFields } from "../Config/formFields/fieldArrays";

export function AOUCategory({ refetch, category }) {
  const formik = useFormik({
    initialValues: {
      label: category?.label ?? "",
      type: category?.type ?? "",
    },
    validateOnMount: true,
    validationSchema: AOUCategoryValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    let request = axiosInstance.post("/category/add", formvalues);

    request
      .then((res) => successToast("CAtegorie ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {addOrUpdateCategoryFields.map((item, i) => (
          <FormFieldProvider {...item} key={"category field nb" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
