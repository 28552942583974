import React, { useState } from "react";
import { NewsLetter } from "../Components/others";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { oneProductUrl } from "../Utils/Queries/products";
import { useParams } from "react-router-dom";
import { SectionLoader } from "../Uikits/Others";
import {
  MdiArrowLeftThin,
  MdiArrowRightThin,
  MdiArrowTopRightThin,
} from "../Uikits/Icons";
import Slider from "react-slick";
import useCustomQuery from "../Hook/query";
import { calcAndFormatProductSellPercent } from "../Utils/Others";

function ProductDetails() {
  const { productId } = useParams();
  const { isLoading, error, data, refetch } = useCustomQuery(
    oneProductUrl + productId
  );

  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const slider1Settings = {
    asNavFor: slider2,
    ref: (slider) => setSlider1(slider),
    arrows: true,
    nextArrow: (
      <span>
        <MdiArrowRightThin />{" "}
      </span>
    ),
    prevArrow: (
      <span>
        <MdiArrowLeftThin />
      </span>
    ),
  };
  const slider2Settings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    asNavFor: slider1,
    ref: (slider) => setSlider2(slider),
    swipeToSlide: true,
    focusOnSelect: true,
  };

  if (isLoading) return <SectionLoader />;
  return (
    <div className="ProductsInfo ">
      {data ? (
        <>
          <h1>Produit {data.data.productNumber}</h1>
          <div className="flex f-wrap jc-se">
            <div className="PI-img">
              <Slider {...slider1Settings}>
                {data.data.pictures.map((item, i) => (
                  <section key={"slider 1 nb" + i} className="big-image">
                    {" "}
                    <img src={item.url} alt="" />
                  </section>
                ))}
              </Slider>
              <br />
              <div className="">
                <Slider {...slider2Settings}>
                  {data.data.pictures.map((item, i) => (
                    <section key={"slider 2 nb" + i} className="small-image">
                      {" "}
                      <img src={item.url} alt="" />
                    </section>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="PI-text ">
              <div className="PI-text-prix flex f-column  ">
                <p>
                  <span>Nom du produit :</span>
                  <b> {data.data.name} </b>
                </p>
                <p>
                  <span>Categorie produit :</span>
                  <b> {data.data.category.label} </b>
                </p>
                <p>
                  <span>Prix d’achat (Fournisseur) :</span>
                  <b> {data.data.supplierPrice} €</b>
                </p>
                <p>
                  <span>Prix de vente :</span>
                  <b> {data.data.price} €</b>
                </p>
                <p>
                  <span>Marge :</span>
                  <b>
                    {" "}
                    {calcAndFormatProductSellPercent(
                      data.data.supplierPrice,
                      data.data.price
                    )}{" "}
                  </b>
                </p>
              </div>
              <div>
                {data.data.supplierLink && (
                  <p className="">
                    <span>Lien Fournisseur:</span>
                    <a href={data.data.supplierLink} target="_blank">
                      Voir <MdiArrowTopRightThin />
                    </a>
                  </p>
                )}
                {data.data.competitorLink && (
                  <p>
                    <span>Lien Concurent:</span>
                    <a href={data.data.competitorLink} target="_blank">
                      Voir <MdiArrowTopRightThin />
                    </a>
                  </p>
                )}
                {data.data.competitorPubLink && (
                  <p>
                    <span>Lien Pub Concurent:</span>
                    <a href={data.data.competitorPubLink} target="_blank">
                      Voir <MdiArrowTopRightThin />
                    </a>
                  </p>
                )}
                <p>
                  <span>Marché potentiel :</span>
                  {data.data.markets.map((item, i) => (
                    <React.Fragment key={"marche nb" + i}>
                      <strong>{item}</strong>
                      {i < data.data.markets.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
            <div className="pi-desc flex f-column">
              <h2>Description:</h2>
              <p dangerouslySetInnerHTML={{ __html: data.data.description }} />
            </div>
          </div>
        </>
      ) : (
        <p>Aucun produit correspondant</p>
      )}
      <NewsLetter />
    </div>
  );
}

export default noNeedAuth(ProductDetails);
