import { Form, FormikProvider, useFormik } from "formik";
import {
  addLPButtonFields,
  addLPParagraphFields,
} from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";

export function AddorUpdateLPButton({ refetch, leadPageTypeId, video }) {
  const formik = useFormik({
    initialValues: {
      buttonText: "",
      buttonLink: "",
      leadPageType: leadPageTypeId,
    },
    validateOnMount: true,
    // validationSchema: NewproductValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    let request = video
      ? axiosInstance.put("/leadPage/update/" + video._id, formvalues)
      : axiosInstance.post("/leadPage/add-button", formvalues);

    request
      .then((res) => successToast("Section ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {(video ? addLPButtonFields : addLPButtonFields).map((item, i) => (
          <FormFieldProvider {...item} key={"video field lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
