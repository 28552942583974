export const allTrainerUrl = "/trainingMentor/all";

export const allStudentUrl = "/user/all?grade=Student";
export const allUserUrl = "/user/all";

export const allTrainingUrl = "/training/all";

export const allTrainingChapterUrl = "/trainingChapter/all";

export const allTrainingSubChapterUrl = "/trainingSubChapter/all";

export const allTrainingQuizzUrl = "/trainingQuizz/all";
export const allTrainingQuizzResultUrl = "/trainingQuizz/result/all";

export const allTrainingAccessUrl = "/trainingAccess/all";

export const allCategoryUrl = "/category/all";
