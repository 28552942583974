import React from "react";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import useCustomQuery from "../Hook/query";
import { allTrainingChapterUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";
import { Link, useParams } from "react-router-dom";
import { AddorUpdateTrainingChapterForm } from "../Components/training/chapter";
import { successToast } from "../Utils/Toast";
import axiosInstance from "../Utils/Axios";
import { formatTrainingDuration } from "../Utils/Others";

function ManageTrainingChapter() {
  const { trainingId } = useParams();
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(
    allTrainingChapterUrl + "/" + trainingId
  );

  function upOrder(content) {
    axiosInstance
      .put(
        "/trainingChapter/up-order/" + content.trainingId + "/" + content._id
      )
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Ordre modifier!");
      });
  }

  function downOrder(content) {
    axiosInstance
      .put(
        "/trainingChapter/down-order/" + content.trainingId + "/" + content._id
      )
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Ordre modifier!");
      });
  }

  function deleteChapter(chapterId) {
    axiosInstance
      .delete("/trainingChapter/delete/" + chapterId)
      .then((res) => successToast("Chapitre supprimer !"))
      .catch((err) => null)
      .finally(() => {
        refetch();
      });
  }

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>Gestion des chapitres de la formations</h1>
      <BorderButton
        text="Nouveau chapitre"
        onClick={() =>
          showModal(
            <AddorUpdateTrainingChapterForm
              trainingId={trainingId}
              refetch={refetch}
            />
          )
        }
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"trainer nb " + i}>
              <h3 className="counter">{item.order}</h3>
              <video src={item.video.url} controls></video>
              <p>
                <strong>Titre du chapitre: </strong>
                {item.title}
              </p>
              <p>
                <strong>Sous-titre du chapitre: </strong>
                {item.subTitle}
              </p>
              {/* <p>
                <strong>Position :</strong>
                {item.order}
              </p> */}

              <p>
                <strong>Nombre de sous-chapitres: </strong>
                {item.subChapters.length}
              </p>
              <p>
                <strong>Duree estimee du chapitre: </strong>
                {formatTrainingDuration(item.duration)}
              </p>

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AddorUpdateTrainingChapterForm
                        trainingId={trainingId}
                        chapter={item}
                        refetch={refetch}
                      />
                    )
                  }
                >
                  Modifier
                </button>
                {item.order > 1 && (
                  <button className="updateBtn" onClick={() => upOrder(item)}>
                    Remonter l'ordre
                  </button>
                )}
                {item.order < data.data.length && (
                  <button className="deleteBtn" onClick={() => downOrder(item)}>
                    Descendre l'ordre
                  </button>
                )}

                <Link
                  className="updateBtn"
                  to={"/manage-training/" + item._id + "/subchapters"}
                >
                  Sous-chapitres
                </Link>
                <Link
                  className="updateBtn"
                  to={"/manage-training/" + item._id + "/quizz"}
                >
                  Quizz Finale
                </Link>

                <button
                  className="deleteBtn"
                  onClick={() => deleteChapter(item._id)}
                >
                  Supprimer
                </button>
              </div>
              {item.ressources && (
                <a
                  href={item.ressources.url}
                  download={item.ressources.filename}
                >
                  Telecharger les ressources
                </a>
              )}
            </article>
          ))
        ) : (
          <p>Aucun chapitre disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageTrainingChapter);
