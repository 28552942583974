import React from "react";
import {
  MdiStairsUp,
  MdiProgressCheck,
  MdiInvoiceTextEdit,
  MdiTrendingUp,
} from "../Uikits/Icons";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import ScrollAnimation from "react-animate-on-scroll";

function About() {
  return (
    <div className="about">
      <div className="about-section">
        <ScrollAnimation animateIn="fadeInLeft" className="Abs-img">
          <img src="/images/shoots/pp3.webp" alt="Marie Pierrot CAKPO" />
          <h2>Marie Pierrot CAKPO</h2>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInRight" className="Abs-texte">
          <p>
            Il y a sept ans lorsque j'étais encore à l'université, j'ai vite
            compris l'importance de trouver des sources de revenus
            complémentaires. Puis un jour en me rendant aux cours j'ai vu sur
            YouTube une vidéo de quelqu'un qui parlait de l'énorme opportunité
            des business en ligne et particulièrement de l'e-commerce. J'ai donc
            décidé de pousser ma curiosité sur ce business qui offrait autant de
            liberté financière et géographique. Je ne savais pas qu'à ce moment
            je me lançais dans une merveilleuse aventure. J'ai commencé donc à
            faire plusieurs recherches, notamment sur les réseaux en particulier
            sur YouTube.
          </p>
          <p>
            Parce qu'à ce moment je n'avais pas les moyens de m'offrir les
            formations exorbitantes proposées par les coachs dans le domaine.
            Après deux ans d'échecs à lancer des boutiques qui tantôt
            fonctionnaient et tantôt pas du tout j'ai décidé de me former avec
            tout l'argent que j'avais économisé pour réaliser mes rêves. Je
            croyais profondément qu'avec l'aide de tous ces coachs et de toutes
            ces formations, je réussirais à finalement m'en sortir dans un
            business à qui j'ai consacré tellement de temps et pour lequel j'ai
            montré tellement de détermination et d'abnégations. Encore un échec
            je respectais les consignes des différents coachs alors qu'est ce
            qui n'allait pas ?
          </p>
        </ScrollAnimation>
      </div>
      <div className="Ab-bannière1">
        <ScrollAnimation animateIn="fadeInUp" className="Ab-b1-text">
          <section>
            <MdiStairsUp />

            <span>
              <h4>Mes défis pendant mon parcours e-commerce</h4>
              <p>
                Un soir d'été en rentrant chez moi je suivais une conférence
                d'un e-commerçant américain très connu qui disait je cite «La
                première cause de l'échec de toutes personnes dans le business
                en ligne est que tout le monde fait comme tout le monde et
                finalement personne n'innove. L'e-commerce peut être un
                environnement compétitif et en constante évolution.»
              </p>
              <p>
                J'ai donc commencé à me poser des questions sur comment mieux
                gérer la concurrence, Que puis-je faire pour mieux optimiser les
                taux de conversion, la logistique, les paiements et augmenter la
                confiance des clients sur mes sites ?
              </p>
            </span>
          </section>
          <section>
            <MdiInvoiceTextEdit />

            <span>
              <h4>Les stratégies pour réussir en e-commerce</h4>
              <p>
                Dans un monde qui va extrêmement vite et dans lequel les plus
                gros vendeurs (Amazon, Zara, Sephora…) offrent une expérience
                client impeccable aux consommateurs vous ne pouvez plus vous
                permettre de faire vos boutiques et de définir votre chaîne de
                vente comme en 2016, 2017 (l'âge d'or de la vente en ligne ) .
                Il faut proposer à vos clients des sites de qualité qui les
                rassurent, il faut dès le départ donner à vos boutiques une
                image de marque. Il vous faudra bien évidemment apprendre et
                maîtriser un canal de vente avec les bonnes stratégies
                actualisées du moment, mais pour aller plus loin il vous faudra
                diversifier vos canaux de ventes et d'acquisitions, et surtout
                penser à l'amélioration de votre service clientèle. Vous pouvez
                également parler de l'importance de la veille concurrentielle et
                de la compréhension de votre marché cible.
              </p>
            </span>
          </section>
          <section>
            <MdiTrendingUp />

            <span>
              <h4>Les tendances actuelles du e-commerce</h4>
              <p>
                L'e-commerce est un domaine en constante évolution, et il est
                important de rester à jour sur les tendances actuelles. Les
                tendances actuelles telles que la montée des achats mobiles, la
                personnalisation des expériences d'achat, l'automatisation des
                processus de marketing et de vente, et les innovations
                technologiques telles que l'intelligence artificielle, rendent
                plus automatisés l'e-commerce et il est plus important d'être à
                l'affût de toutes les nouveautés.
              </p>
            </span>
          </section>
        </ScrollAnimation>
        {/* <div className="Ab-b1-img">
          <img src="/images/image-2.png" alt="Marie Pierrot CAKPO" />
        </div> */}
      </div>
      <div className="about-bottom">
        <h4>Les Leçons apprises</h4>
        <p>
          Il ressort donc de mon expérience 11 conseils les plus importants pour
          réussir en e-commerce :
        </p>
        <div className="ab-b-section">
          <ScrollAnimation animateIn="slideInLeft" className="abb-section-left">
            <span>
              <MdiProgressCheck />
              <p>
                Choisissez le bon produit : Le choix d'un produit rentable et en
                demande est la clé du succès en e-commerce. Vous devriez
                effectuer une recherche approfondie pour trouver un produit qui
                répond aux besoins de votre public cible.
              </p>
            </span>
            <span>
              <MdiProgressCheck />
              <p>
                Établissez une stratégie de marketing solide : Il est important
                de développer une stratégie de marketing solide pour promouvoir
                votre boutique e-commerce. Vous pouvez utiliser divers canaux de
                marketing, tels que les médias sociaux, le marketing par
                courriel, les publicités payantes et les blogs pour atteindre
                votre public cible.
              </p>
            </span>
            <span>
              <MdiProgressCheck />
              <p>
                Sélectionnez un bon fournisseur : Vous devriez choisir un bon
                fournisseur fiable qui offre des produits de haute qualité à des
                prix compétitifs. Assurez-vous également que le fournisseur
                expédie rapidement les produits.
              </p>
            </span>
            <span>
              <MdiProgressCheck />
              <p>
                Créez une boutique en ligne professionnelle : Votre boutique
                devrait avoir une conception professionnelle et facile à
                naviguer. Elle doit être conviviale et offrir une expérience
                utilisateur agréable.
              </p>
            </span>
            <span>
              <MdiProgressCheck />
              <p>
                Optimisez votre site web pour le référencement : L'optimisation
                de votre site web pour les moteurs de recherche peut aider à
                améliorer votre visibilité en ligne. Utilisez des mots-clés
                pertinents dans votre contenu pour augmenter vos chances de
                figurer en tête des résultats de recherche.
              </p>
            </span>
          </ScrollAnimation>
          <hr />
          <ScrollAnimation
            animateIn="slideInRight"
            className="abb-section-right"
          >
            <span>
              <MdiProgressCheck />
              <p>
                Offrez un excellent service client : Assurez-vous de répondre
                rapidement aux questions de vos clients et de résoudre
                rapidement les problèmes. Un excellent service client peut aider
                à fidéliser les clients et à générer des ventes répétées.
              </p>
            </span>

            <span>
              <MdiProgressCheck />
              <p>
                Établissez un processus de gestion des stocks : La gestion
                efficace des stocks est essentielle en e-commerce Vous devez
                maintenir des niveaux de stock adéquats et surveiller de près
                les niveaux de stock pour éviter les pénuries.
              </p>
            </span>

            <span>
              <MdiProgressCheck />
              <p>
                Soyez transparent avec vos clients : Informez vos clients des
                délais de livraison et des frais d'expédition dès le début. Cela
                peut aider à établir une relation de confiance avec vos clients.
              </p>
            </span>

            <span>
              <MdiProgressCheck />
              <p>
                Suivez vos données : Utilisez des outils d'analyse pour
                surveiller vos performances en matière de vente. Cela vous
                permettra de comprendre ce qui fonctionne et ce qui ne
                fonctionne pas, et de prendre des décisions éclairées pour
                améliorer votre boutique.
              </p>
            </span>

            <span>
              <MdiProgressCheck />
              <p>
                Soyez patient et persévérant : Le succès en e-commerce ne se
                produit pas du jour au lendemain. Soyez patient et persévérant
                dans vos efforts de marketing et de vente, et continuez à
                apporter des améliorations à votre boutique en ligne.
              </p>
            </span>

            <span>
              <MdiProgressCheck />
              <p>
                Faites-vous accompagner par quelqu'un qui vous suit réellement
                et qui s'est déjà rendu là où vous allez : vers la liberté
                financière
              </p>
            </span>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
}

export default noNeedAuth(About);
