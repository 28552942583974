import React from "react";
import { BackgroundButton } from "../../Uikits/Button";
import ScrollAnimation from "react-animate-on-scroll";

function HomeWhoIAm() {
  return (
    <div className="home-whoIAm flex">
      <ScrollAnimation animateIn="fadeInLeft" className="hw-img">
        <img src="/images/shoots/whoIAm.webp" alt="" />
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="fadeInRight"
        className="hw-text flex f-column "
      >
        <h3>
          Qui est Marie
          <br /> Pierrot CAKPO ?
        </h3>

        <p>
          Il y a sept ans lorsque j'étais encore à l'université, j'ai vite
          compris l'importance de trouver des sources de revenus
          complémentaires. Puis un jour en me rendant aux cours j'ai vu sur
          YouTube une vidéo de quelqu'un qui parlait de l'énorme opportunité des
          business en ligne et particulièrement de l'e-commerce. 
        </p>

        <BackgroundButton text="Découvrir plus" isLink={true} link="/about" />
      </ScrollAnimation>
    </div>
  );
}

export default HomeWhoIAm;
