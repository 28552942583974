import React from "react";
import useModalStore from "../Stores/Modal";
import useCustomQuery from "../Hook/query";
import { allTrainingQuizzResultUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";
import { successToast } from "../Utils/Toast";
import axiosInstance from "../Utils/Axios";
import { Link } from "react-router-dom";

function ManageTrainingQuizzResults() {
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(
    allTrainingQuizzResultUrl
  );

  function deleteQuizzQuestion(qId) {
    axiosInstance
      .delete("/trainingQuizz/delete/" + qId)
      .then((res) => successToast("Quizz supprimer !"))
      .catch((err) => null)
      .finally(() => {
        refetch();
      });
  }

  if (isLoading) return <SectionLoader />;

  if (!data.data)
    return (
      <div className="dataManager">
        <p>Aucun resultat de quizz pour le moment</p>
      </div>
    );
  return (
    <div className="dataManager">
      <h1>Resultats de quizz</h1>
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"quizz question nb " + i}>
              <p>Eleves: {item.userId.name} </p>
              <p>Relatif au chapitre: {item.chapterId.title} </p>
              <p>Score: {item.score} </p>

              <div>
                <button
                  className="deleteBtn"
                  onClick={() => deleteQuizzQuestion(item._id)}
                >
                  Supprimer
                </button>
                <Link to={"/manage-training/" + item.chapterId._id + "/quizz"}>
                  Voir le quizz
                </Link>
              </div>
            </article>
          ))
        ) : (
          <p>Aucun resultats de quizz disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageTrainingQuizzResults);
