import React from "react";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import useCustomQuery from "../Hook/query";
import { allUserUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";
import { AOUpdateUserForm } from "../Components/user";
import axiosInstance from "../Utils/Axios";
import { successToast } from "../Utils/Toast";
import { AOUStudentTrainingAccess } from "../Components/training/access";
import { userGradWithSuperAdminOptions } from "../Config/formFields/others";

function ManageUser() {
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(allUserUrl);

  function deleteUser(user) {
    axiosInstance
      .delete("/user/delete/" + user._id)
      .then((res) => successToast("Utilisateur supprimer !"))
      .catch((err) => null)
      .finally(() => {
        refetch();
      });
  }

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>Gestion des utilisateurs</h1>
      <BorderButton
        text="Nouvel utilisateur"
        onClick={() => showModal(<AOUpdateUserForm refetch={refetch} />)}
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"trainer nb " + i}>
              <p>
                <strong>Nom complet: </strong>
                {item.name}
              </p>
              <p>
                <strong>Email: </strong>
                {item.email}
              </p>
              <p>
                <strong>Grade: </strong>
                {
                  userGradWithSuperAdminOptions.filter(
                    (grd) => item.grade == grd.value
                  )[0].label
                }
              </p>

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AOUpdateUserForm user={item} refetch={refetch} />
                    )
                  }
                >
                  Modifier
                </button>
                {item.grade == "Student" && (
                  <button
                    className="updateBtn"
                    onClick={() =>
                      showModal(
                        <AOUStudentTrainingAccess studentId={item._id} />
                      )
                    }
                  >
                    Gestion des access
                  </button>
                )}
                {item.grade != "SuperAdmin" && (
                  <button
                    className="deleteBtn"
                    onClick={() => deleteUser(item)}
                  >
                    Supprimer
                  </button>
                )}
              </div>
            </article>
          ))
        ) : (
          <p>Aucune utilisateur disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageUser);
