import { create } from "zustand";

const useThemeStore = create((set) => ({
  theme: localStorage.getItem("theme") ?? "light",
  toggleTheme: (oldTheme) => {
    let theme;
    console.log(theme, document.documentElement.getAttribute("data-theme"));

    if (oldTheme === "light") {
      theme = "dark";
    } else {
      theme = "light";
    }
    set({ theme });
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  },
}));

export default useThemeStore;
