import React from "react";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import { AddorUpdateTrainerForm } from "../Components/training/trainer";
import useCustomQuery from "../Hook/query";
import { allTrainerUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";

function ManageTrainer() {
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(allTrainerUrl);

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>Gestion des formateurs</h1>
      <BorderButton
        text="Nouveau formateur"
        onClick={() => showModal(<AddorUpdateTrainerForm refetch={refetch} />)}
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"trainer nb " + i}>
              <img src={item.picture.url} alt={"picture of trainer " + i} />
              <p>
                <strong>Nom complet: </strong>
                {item.fullname}
              </p>
              <p>
                <strong>Email: </strong>
                {item.email}
              </p>

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AddorUpdateTrainerForm
                        trainer={item}
                        refetch={refetch}
                      />
                    )
                  }
                >
                  Modifier
                </button>

                {/* <button
                  className="deleteBtn"
                  onClick={() => deleteFormation(item._id)}
                >
                  Supprimer
                </button> */}
              </div>
            </article>
          ))
        ) : (
          <p>Aucune formateur disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageTrainer);
