import { Form, FormikProvider, useFormik } from "formik";
import { addNewTrainingSubChapterFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import { NewTrainingSubChapterValidation } from "../../Config/formFields/fieldsValidations";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";

export function AddorUpdateTrainingSubChapterForm({
  refetch,
  chapterId,
  subChapter,
}) {
  const formik = useFormik({
    initialValues: {
      title: subChapter?.title ?? "",
      description: subChapter?.description ?? "",
      duration: subChapter?.duration ?? 0,
      trainingVideo: "",
      trainingRessources: "",
    },
    validateOnMount: true,
    validationSchema: NewTrainingSubChapterValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formdata = new FormData();

    formdata.append("title", formvalues.title);
    formdata.append("duration", formvalues.duration);
    formdata.append("description", formvalues.description);
    formdata.append("trainingVideo", formvalues.trainingVideo);
    if (formvalues.trainingRessources) {
      formdata.append("trainingRessources", formvalues.trainingRessources);
    }
    formdata.append("chapterId", chapterId);

    let request = subChapter
      ? axiosInstance.put(
          "/trainingSubChapter/update/" + subChapter._id,
          formdata
        )
      : axiosInstance.post("/trainingSubChapter/create", formdata);

    request
      .then((res) => successToast("Sous-Chapitre ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {addNewTrainingSubChapterFields.map((item, i) => (
          <FormFieldProvider
            {...item}
            key={"training sub chapt field lol" + i}
          />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
