import { Form, FormikProvider, useFormik } from "formik";
import { addLPVGalleryFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";
import { LeadPageGalleryValidation } from "../../Config/formFields/fieldsValidations";

export function AddorUpdateLPGallery({ refetch, leadPageTypeId, video }) {
  const formik = useFormik({
    initialValues: {
      otherMedias: [],
      leadPageType: leadPageTypeId,
    },
    validateOnMount: true,
    validationSchema: LeadPageGalleryValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formData = new FormData();
    formData.append("leadPageType", formvalues.leadPageType);

    for (let i = 0; i < formvalues.otherMedias.length; i++) {
      const element = formvalues.otherMedias[i];
      formData.append("otherMedias", element);
    }

    let request = video
      ? axiosInstance.put("/leadPage/update/" + video._id, formData)
      : axiosInstance.post("/leadPage/add-gallery", formData);

    request
      .then((res) => successToast("Section ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {(video ? addLPVGalleryFields : addLPVGalleryFields).map((item, i) => (
          <FormFieldProvider {...item} key={"gallery field lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
