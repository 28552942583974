import React, { useState } from "react";
import {
  allTrainingAccessUrl,
  allTrainingChapterUrl,
  allTrainingUrl,
} from "../../Utils/Queries/training";
import { SectionLoader } from "../../Uikits/Others";
import useCustomQuery from "../../Hook/query";
import axiosInstance from "../../Utils/Axios";
import { errorToast, successToast } from "../../Utils/Toast";

export function AOUStudentTrainingAccess({ studentId }) {
  const [backTo, setBackTo] = useState(true);
  const [trainingId, setTI] = useState(null);
  return (
    <div>
      {backTo ? (
        <AllTrainingScreen
          studentId={studentId}
          setTI={setTI}
          setBackTo={setBackTo}
        />
      ) : (
        <AllTrainingChapterAndSubChapter
          studentId={studentId}
          trainingId={trainingId}
        />
      )}
    </div>
  );
}

function AllTrainingScreen({ setTI, setBackTo }) {
  const { isLoading, error, data, refetch } = useCustomQuery(allTrainingUrl);
  if (isLoading) return <SectionLoader />;
  if (data.data.length == 0) return <p>Aucune formation disponible</p>;
  return (
    <div>
      <section className="modalTrainingList">
        <h1>Selectionnez le chapitre dont vous voulez editer les access</h1>
        {data.data.map((item, i) => (
          <h3
            key={"trainer nb " + i}
            onClick={() => {
              setTI(item._id);
              setBackTo(false);
            }}
          >
            {item.title}
          </h3>
        ))}
      </section>
    </div>
  );
}

function AllTrainingChapterAndSubChapter({ studentId, trainingId }) {
  const [allowedSubChapters, setASC] = useState([]);
  const {
    isLoading: userAccessLoading,
    data: userAccess,
    refetch: refetchUserAcsess,
  } = useCustomQuery(
    allTrainingAccessUrl + "/" + studentId + "/TrainingSubChapter"
  );
  const {
    isLoading,
    error,
    data,
    refetch: refetchAllChapter,
  } = useCustomQuery(allTrainingChapterUrl + "/" + trainingId);

  function handleSelect(id) {
    let requestUrl = null;

    if (checkUserSCAccess(id)) {
      requestUrl = "/trainingAccess/disallow/";
    } else {
      requestUrl = "/trainingAccess/allow/";
    }
    axiosInstance
      .post(requestUrl, {
        userId: studentId,
        entityId: id,
        entityType: "TrainingSubChapter",
      })
      .then((res) => successToast("Access allouer !"))
      .catch((err) => errorToast("Erreur Survenue"))
      .finally(() => {
        refetchAllChapter();
        refetchUserAcsess();
      });
  }

  function checkUserSCAccess(scId) {
    let filter = userAccess.data.filter((item) => item.entityId == scId);
    if (filter && filter.length > 0) {
      return true;
    }
    return false;
  }
  if (isLoading || userAccessLoading) return <SectionLoader />;
  if (data.data.length == 0) return <p>Aucun chapitre disponible</p>;
  return (
    <div>
      <section className="">
        {data.data.map((item, i) => (
          <article
            key={"sub chapt train nb " + i}
            className="eachChapterAndSubChapters"
          >
            <h3>{item.title}</h3>
            <ul>
              {item.subChapters.map((sc, j) => (
                <li key={i + "sc nb" + j}>
                  <span>{sc.title}</span>
                  <button
                    className={
                      checkUserSCAccess(sc._id) ? "alreadyAllowed" : ""
                    }
                    onClick={() => handleSelect(sc._id)}
                  >
                    Allouer
                  </button>
                </li>
              ))}
            </ul>
          </article>
        ))}
      </section>
    </div>
  );
}
