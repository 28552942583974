import { Form, FormikProvider, useFormik } from "formik";
import { addNewTrainerFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import { NewTrainerValidation } from "../../Config/formFields/fieldsValidations";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";

export function AddorUpdateTrainerForm({ refetch, trainer }) {
  const formik = useFormik({
    initialValues: {
      email: trainer?.email ?? "",
      fullname: trainer?.fullname ?? "",
      picture: "",
    },
    validateOnMount: true,
    validationSchema: NewTrainerValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formdata = new FormData();

    formdata.append("email", formvalues.email);
    formdata.append("fullname", formvalues.fullname);
    formdata.append("mentorPicture", formvalues.picture);

    let request = trainer
      ? axiosInstance.put("/trainingMentor/update/" + trainer._id, formdata)
      : axiosInstance.post("/trainingMentor/create", formdata);

    request
      .then((res) => successToast("Formateur ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {addNewTrainerFields.map((item, i) => (
          <FormFieldProvider {...item} key={"trainer field lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
