import { Form, FormikProvider, useFormik } from "formik";
import { addNewTrainingQuizzFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import { NewTrainingQuizzValidation } from "../../Config/formFields/fieldsValidations";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";

export function AOUTrainingQuizzForm({ refetch, chapterId, quizz }) {
  const formik = useFormik({
    initialValues: {
      question: quizz?.question ?? "",
      suggestedAnswers: quizz?.suggestedAnswers ?? [],
      rightAnswer: quizz?.rightAnswer ?? 0,
      chapterId,
    },
    validateOnMount: true,
    validationSchema: NewTrainingQuizzValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    let request = quizz
      ? axiosInstance.put(
          "/trainingQuizz/update/quizz-questions-and-answers/" + quizz._id,
          formvalues
        )
      : axiosInstance.post(
          "/trainingQuizz/create/quizz-questions-and-answers",
          formvalues
        );

    request
      .then((res) => successToast("Question de quizz ajouter/modifier !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {addNewTrainingQuizzFields.map((item, i) => (
          <FormFieldProvider {...item} key={"quizz field lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
