import { Form, FormikProvider, useFormik } from "formik";
import { addNewTrainingFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import {
  NewTrainingValidation,
  UpTrainingValidation,
} from "../../Config/formFields/fieldsValidations";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";

export function AddorUpdateTrainingForm({ refetch, training }) {
  const formik = useFormik({
    initialValues: {
      title: training?.title ?? "",
      subTitle: training?.subTitle ?? "",
      level: training?.level ?? "",
      tags: training?.tags ?? [],
      description: training?.description ?? "",
      mentorId: training?.mentorId._id ?? "",
      miniature: "",
      isFree: training?.isFree.toString() ?? "",
      studentsCount: training?.studentsCount ?? "",
    },
    validateOnMount: true,
    validationSchema: training ? UpTrainingValidation : NewTrainingValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formdata = new FormData();

    formdata.append("title", formvalues.title);
    formdata.append("subTitle", formvalues.subTitle);
    formdata.append("level", formvalues.level);
    formdata.append("description", formvalues.description);
    formdata.append("mentorId", formvalues.mentorId);
    formdata.append("isFree", JSON.parse(formvalues.isFree));
    formdata.append("studentsCount", formvalues.studentsCount);

    if (formvalues.miniature) {
      formdata.append("miniature", formvalues.miniature);
    }

    for (let k = 0; k < formvalues.tags.length; k++) {
      formdata.append("tags", formvalues.tags[k]);
    }

    let request = training
      ? axiosInstance.put("/training/update/" + training._id, formdata)
      : axiosInstance.post("/training/create", formdata);

    request
      .then((res) => successToast("Formation ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {addNewTrainingFields.map((item, i) => (
          <FormFieldProvider {...item} key={"training lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
