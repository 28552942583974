"use client";
import useAuthStore from "../Stores/Auth";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Sidebar from "../Uikits/Sidebar";
import { AppLoader } from "../Uikits/Others";
import { allAdministrationRoutes } from "../Utils/Routes";

export const needAuth = (WrappedComponent, specificRole = false) => {
  return (props) => {
    const initAuth = useAuthStore((state) => state.initAuth);
    const location = useLocation();
    const user = useAuthStore((state) => state.user);
    const userLoading = useAuthStore((state) => state.userLoading);
    const userToken = useAuthStore((state) => state.userToken);
    const userIsConnected = useAuthStore((state) => state.userIsConnected);
    const getUserConnected = useAuthStore((state) => state.getUserConnected);
    const logout = useAuthStore((state) => state.logout);
    const navigate = useNavigate();
    const matchedRoute = allAdministrationRoutes.find((route) =>
      matchPath(route, location.pathname)
    );

    useEffect(() => {
      initAuth();
    }, []);
    useEffect(() => {
      if (userToken && userIsConnected) {
        getUserConnected(userToken);
      } else {
        // logout();
      }
    }, [userIsConnected, userToken]);

    if (userLoading) return <AppLoader />;
    else if (!userLoading && !user) {
      return navigate("/login");
    }

    if (
      user.grade == "Manager" &&
      location.pathname != "/dashboard" &&
      !user.managerAllowedOptions.includes(JSON.stringify(matchedRoute.id))
    ) {
      return (
        <>
          <Sidebar />
          <main className="connectedMain">
            <h1
              style={{
                textAlign: "center",
              }}
            >
              Sorry! Vous ne pouvez pas acceder a cette page
            </h1>
          </main>
        </>
      );
    }
    return (
      <>
        <Sidebar />
        <main className="connectedMain">
          <WrappedComponent {...props} />
        </main>
      </>
    );
  };
};
