import React from "react";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import useCustomQuery from "../Hook/query";
import { allTrainingUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";
import { AddorUpdateTrainingForm } from "../Components/training";
import { Link } from "react-router-dom";
import { formatTrainingDuration } from "../Utils/Others";
import { successToast } from "../Utils/Toast";
import axiosInstance from "../Utils/Axios";

function ManageTraining() {
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(allTrainingUrl);

  function deleteTraining(training) {
    axiosInstance
      .delete("/training/delete/" + training._id)
      .then((res) => successToast("Eleve supprimer !"))
      .catch((err) => null)
      .finally(() => {
        refetch();
      });
  }

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>Gestion des formations</h1>
      <BorderButton
        text="Nouvelle formation"
        onClick={() => showModal(<AddorUpdateTrainingForm refetch={refetch} />)}
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"trainer nb " + i}>
              <img src={item.miniature.url} alt={"picture of trainer " + i} />
              <p>
                <strong>Titre de la formation: </strong>
                {item.title}
              </p>
              <p>
                <strong>Est gratuite ?: </strong>
                {item.isFree ? "Oui" : "Non"}
              </p>
              <p>
                <strong>Formateur: </strong>
                {item.mentorId.fullname}
              </p>
              <p>
                <strong>Nombre de chapitre: </strong>
                {item.chapters.length}
              </p>
              <p>
                <strong>Duree estimee de la formation: </strong>
                {formatTrainingDuration(item.duration)}
              </p>
              <p>
                <strong>Nombre d'etudiants: </strong>
                {item.studentsCount}
              </p>

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AddorUpdateTrainingForm
                        training={item}
                        refetch={refetch}
                      />
                    )
                  }
                >
                  Modifier
                </button>
                <Link
                  className="updateBtn"
                  to={"/manage-training/" + item._id + "/chapters"}
                >
                  Editer chapitres et sous-chapitres
                </Link>
                <button
                  className="deleteBtn"
                  onClick={() => deleteTraining(item)}
                >
                  Supprimer
                </button>
              </div>
            </article>
          ))
        ) : (
          <p>Aucune formations disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageTraining);
