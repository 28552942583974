import { Form, FormikProvider, useFormik } from "formik";
import { addNewTrainingChapterFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import { NewTrainingChapterValidation } from "../../Config/formFields/fieldsValidations";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";

export function AddorUpdateTrainingChapterForm({
  refetch,
  trainingId,
  chapter,
}) {
  const formik = useFormik({
    initialValues: {
      title: chapter?.title ?? "",
      subTitle: chapter?.subTitle ?? "",
      description: chapter?.description ?? "",
      trainingVideo: "",
      trainingRessources: "",
    },
    validateOnMount: true,
    validationSchema: NewTrainingChapterValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formdata = new FormData();

    formdata.append("title", formvalues.title);
    formdata.append("subTitle", formvalues.subTitle);
    formdata.append("description", formvalues.description);
    formdata.append("trainingVideo", formvalues.trainingVideo);
    if (formvalues.trainingRessources) {
      formdata.append("trainingRessources", formvalues.trainingRessources);
    }
    formdata.append("trainingId", trainingId);

    let request = chapter
      ? axiosInstance.put("/trainingChapter/update/" + chapter._id, formdata)
      : axiosInstance.post("/trainingChapter/create", formdata);

    request
      .then((res) => successToast("Chapitre ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {addNewTrainingChapterFields.map((item, i) => (
          <FormFieldProvider {...item} key={"training chapter field lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
