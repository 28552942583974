import { Form, FormikProvider, useFormik } from "formik";
import {
  addNewUserFields,
  updateUserFields,
} from "../Config/formFields/fieldArrays";
import FormFieldProvider from "../Uikits/form";
import { FormButton } from "../Uikits/Button";
import {
  NewStudentValidation,
  UpStudentValidation,
} from "../Config/formFields/fieldsValidations";
import axiosInstance from "../Utils/Axios";
import useModalStore from "../Stores/Modal";
import { errorToast, successToast } from "../Utils/Toast";
import { gradeAllowedOptionsField } from "../Config/formFields/fields";

export function AOUpdateUserForm({ refetch, user }) {
  const formik = useFormik({
    initialValues: {
      grade: user?.grade ?? "",
      managerAllowedOptions: user?.managerAllowedOptions ?? [],
      email: user?.email ?? "",
      fullname: user?.name ?? "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: user ? UpStudentValidation : NewStudentValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    let request = user
      ? axiosInstance.put("/user/update-by-admin/" + user._id, formvalues)
      : axiosInstance.post("/user/create-by-admin", formvalues);

    request
      .then((res) => successToast("Utilisateur ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }

  let fields = user
    ? values.grade == "Manager"
      ? [...updateUserFields, gradeAllowedOptionsField]
      : updateUserFields
    : values.grade == "Manager"
    ? [...addNewUserFields, gradeAllowedOptionsField]
    : addNewUserFields;
  return (
    <FormikProvider value={formik}>
      <Form>
        {fields.map((item, i) => (
          <FormFieldProvider {...item} key={"new user form field" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
