import React, { useEffect, useState } from "react";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { useQuery } from "@tanstack/react-query";
import { getAllProducts } from "../Utils/Queries/products";
import { useNavigate } from "react-router-dom";
import { SectionLoader } from "../Uikits/Others";
import { calcAndFormatProductSellPercent } from "../Utils/Others";
import useCustomQuery from "../Hook/query";
import { allCategoryUrl } from "../Utils/Queries/training";
import FormFieldProvider from "../Uikits/form";
import { FormikProvider, useFormik } from "formik";
import {
  productCategory,
  productCategoryForFilter,
} from "../Config/formFields/fields";

function Products() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allProducts"],
    queryFn: getAllProducts,
  });
  const {
    isLoading: categoryLoading,
    error: catError,
    data: categories,
  } = useCustomQuery(allCategoryUrl);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const formik = useFormik({
    initialValues: { category: "" },
  });

  useEffect(() => {
    if (!isLoading) {
      setProducts(data.data);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!categoryLoading && data.data && data.data.length > 0) {
      handleFilter(formik.values.category);
    }
  }, [formik.values.category]);

  function handleProductSearch(e) {
    const { value } = e.target;
    const copy = [...data.data];
    const filter = copy.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        value == item.productNumber
    );
    setProducts(filter);
  }

  function handleFilter(values) {
    const copy = [...data.data];
    if (values) {
      const filter = copy.filter((item) => values == item.category);
      setProducts(filter);
    } else {
      setProducts(copy);
    }
  }
  if (isLoading) return <SectionLoader />;
  return (
    <div className="products">
      <section className="productsSearchBox flex f-wrap ai-c">
        <input
          type="text"
          placeholder="Chercher un produit avec le nom ou le numero du produit"
          onChange={handleProductSearch}
        />
        {!categoryLoading && (
          <FormikProvider value={formik}>
            <FormFieldProvider {...productCategoryForFilter} />
          </FormikProvider>
        )}
      </section>
      {products.length == 0 ? (
        <p>Aucun produit disponible</p>
      ) : (
        <>
          <div className="pr-cards flex f-wrap">
            {products.map((item, i) => (
              <div
                className="pr-card-section"
                key={"product card" + i}
                onClick={() => navigate("/product/" + item._id)}
              >
                <section className="flex ">
                  <img src={item.pictures[0].url} alt="" />
                </section>
                <section className="flex prcs-texts f-wrap">
                  <div className="flex f-column">
                    <p>{item.name} </p>
                    <b>{item.price} €</b>
                  </div>
                  <div className="flex f-column">
                    <p>Marge:</p>
                    <b>
                      {calcAndFormatProductSellPercent(
                        item.supplierPrice,
                        item.price
                      )}{" "}
                    </b>
                  </div>
                  <div
                    className="flex ai-c"
                    style={{
                      width: "100%",
                    }}
                  >
                    <p>Numero du produit:</p> <b>{item.productNumber}</b>
                  </div>
                </section>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default noNeedAuth(Products);
