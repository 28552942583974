import {
  timeOptions,
  yesOrNoOptions,
  categoryOptions,
  trainingLevelOptions,
  targetApplicationOptions,
  userGradeOptions,
  gradePermissionOptions,
} from "./others";
import { allCategoryUrl, allTrainerUrl } from "../../Utils/Queries/training";

export const allTimes = ["Mois", "An"];

const allVideoAccept = "video/mp4,video/x-m4v,video/*";
const allImageAccept = "image/*";
const allZipAccept = ".zip";

const genericEmailTypeAndName = {
  fieldType: "email",
  name: "email",
  ph: "Ex: monmail@gmail.com",
};

const genericFullnameTypeAndName = {
  fieldType: "text",
  name: "fullname",
  ph: "Ex: Jean Claude Vd",
};

const genericPasswordTypeAndName = {
  fieldType: "password",
  name: "password",
  ph: "Ex: MOnMdp123",
};

const genericPictureTypeAndName = {
  fieldType: "file",
  name: "picture",
  accept: allImageAccept,
};

const genericTitleTypeAndName = {
  fieldType: "text",
  name: "title",
  ph: "Ex: Banane,Shopify,etc...",
};

const genericSubtitleTypeAndName = {
  fieldType: "text",
  name: "subTitle",
};

export const nameField = {
  fieldType: "text",
  label: "Votre nom",
  ph: "Marie,Mario",
  name: "name",
};

export const emailField = {
  ...genericEmailTypeAndName,
  label: "Votre email",
  ph: "abc@gmail.com",
};

export const subjectField = {
  fieldType: "text",
  label: "Le sujet du contact",
  ph: "Le Sujet",
  name: "subject",
};

export const messageField = {
  fieldType: "textarea",
  label: "Votre message",
  ph: "Message ici",
  name: "message",
};
export const passwordField = {
  ...genericPasswordTypeAndName,
  label: "Mot de passe",
  ph: "MonMotdePasse@1234",
};
export const passwordConfirmationField = {
  fieldType: "password",
  label: "Confirmation de mot de passe",
  ph: "MonMotdePasse@1234",
  name: "passwordConfirmation",
};

export const availabilityDate = {
  fieldType: "date",
  label: "Type de Disponibilite",
  name: "availabilityDate",
};

export const availabilityTime = {
  fieldType: "time",
  label: "Valeur de Disponibilite",
  ph: "Ex: 1,2,12",
  name: "availabilityTime",
};

export const formationName = {
  fieldType: "text",
  label: "Titre de la formation",
  ph: "Ex: Standard,Basique",
  name: "name",
};

export const formationPrice = {
  fieldType: "number",
  label: "Prix de la formation",
  name: "price",
};

export const formationTime = {
  fieldType: "radio",
  label: "Duree de la formation",
  name: "time",
  options: timeOptions,
  valueKey: "value",
  labelKey: "label",
};

export const formationDetails = {
  fieldType: "multipleText",
  label: "Details de la formation",
  name: "details",
  newDataBtn: "Ajouter un nouveau detail",
  newDataPh: "Nouveau detail",
};

export const productMarkets = {
  fieldType: "multipleText",
  label: "marché pour se lancer",
  name: "markets",
  newDataBtn: "Ajouter un nouveau marché",
  newDataPh: "Nouveau marché",
};

export const productId = {
  fieldType: "text",
  label: "Id du produit",
  name: "productNumber",
};

export const productPublicationDateTime = {
  fieldType: "datetime",
  label: "Date de dsiponibilite du produit",
  name: "publicationDate",
};

export const productIsVisible = {
  fieldType: "radio",
  label: "Produit visible",
  name: "isVisible",
  options: yesOrNoOptions,
  valueKey: "value",
  labelKey: "label",
};

export const modelDesc = {
  fieldType: "text",
  label: "Une mini description",
  name: "description",
  ph: "Je suis atachiante",
};

export const appName = {
  fieldType: "select",
  label: "Pour l'application",
  name: "appName",
  valueKey: "value",
  labelKey: "label",
  chooseLabelKey: "Choisir application",
  options: targetApplicationOptions,
};

export const autorName = {
  fieldType: "text",
  label: "Nom de lauteur",
  ph: "Ex: John Doe",
  name: "autorName",
};

export const autorText = {
  fieldType: "text",
  label: "Commentaire(texte)",
  ph: "Ex: Tres bonne formation",
  name: "text",
};

export const autorPicture = {
  fieldType: "file",
  label: "La photo de lauteur",
  name: "autorPicture",
  accept: allImageAccept,
};
export const autorAudioOrVideoField = {
  fieldType: "file",
  label: "Commentaire(Audio/Video)",
  name: "audioOrVideo",
  accept: "audio/*,video/*",
};

export const mediaField = {
  fieldType: "file",
  label: "Le fichier",
  name: "media",
  accept: "image/*,video/*",
};

export const productPictures = {
  fieldType: "files",
  label: "Les images du produits",
  name: "pictures",
  accept: allImageAccept,
  multiple: true,
};

export const productCategory = {
  fieldType: "selectWithApi",
  label: "Categorie",
  name: "category",
  valueKey: "_id",
  labelKey: "label",
  chooseLabelKey: "Choisir categorie",
  apiUrl: allCategoryUrl,
};

export const productCategoryForFilter = {
  fieldType: "selectWithApi",
  name: "category",
  valueKey: "_id",
  labelKey: "label",
  chooseLabelKey: "Choisir categorie",
  apiUrl: allCategoryUrl,
  firstOption: { _id: "", label: "Tout les produits" },
};

export const productName = {
  fieldType: "text",
  label: "Nom du produit",
  ph: "Ex: Perle rare",
  name: "name",
};

export const productDesc = {
  fieldType: "editor",
  label: "Description du produit",
  ph: "Ex: Description...",
  name: "description",
  additionalOptions: [],
};

export const productSupplierPrice = {
  fieldType: "number",
  label: "Prix du fournisseur",
  ph: "Ex: Perle rare",
  name: "supplierPrice",
};

export const productPrice = {
  fieldType: "number",
  label: "Prix",
  ph: "Ex: Perle rare",
  name: "price",
};

export const productSupplierLink = {
  fieldType: "text",
  label: "Lien fournisseur",
  ph: "Ex: https://www.youtube.com/...",
  name: "supplierLink",
};

export const productCompetitorLink = {
  fieldType: "text",
  label: "Lien competiteur",
  ph: "Ex: https://www.youtube.com...",
  name: "competitorLink",
};

export const productCompetitorPubLink = {
  fieldType: "text",
  label: "Lien Pub competiteur",
  ph: "Ex: https://www.youtube.com...",
  name: "competitorPubLink",
};

export const lpTypeLabel = {
  fieldType: "text",
  label: "Label de la page lead",
  ph: "Ex: Pays,Numero etc...",
  name: "label",
};

export const lpParagraph = {
  fieldType: "editor",
  label: "Paragraphe de la section",
  ph: "Ex: lorem ipsum.....",
  name: "paragraph",
  additionalOptions: [],
};

export const lpButtontext = {
  fieldType: "text",
  label: "Text du bouton",
  ph: "Ex: Cliquez moi.....",
  name: "buttonText",
};

export const lpButtonLink = {
  fieldType: "text",
  label: "Lien du bouton",
  ph: "Ex: https://google.com.....",
  name: "buttonLink",
};

export const lpVideo = {
  fieldType: "file",
  label: "Video de la section",
  name: "otherMedias",
  accept: allVideoAccept,
};

export const lpPdfImg = {
  fieldType: "file",
  label: "Visuel du pdf",
  name: "otherMedias",
  accept: allImageAccept,
};

export const lpPdf = {
  fieldType: "file",
  label: "PDf de la section",
  name: "documents",
  accept: "application/pdf",
};

export const lpGallery = {
  fieldType: "files",
  label: "Gallerie d'images de la section",
  name: "otherMedias",
  accept: allImageAccept,
};

export const subject2Field = {
  fieldType: "text",
  label: "Sujet de la Newletter",
  ph: "Ex: Les pubs en ecommerce...",
  name: "subject",
};

export const messageEditorField = {
  fieldType: "editor",
  label: "Message",
  name: "message",
  additionalOptions: [
    ["fontColor", "hiliteColor", "textStyle"],
    ["table", "link", "image"],
  ],
};

export const stripeLinkField = {
  fieldType: "text",
  label: "Lien stripe",
  name: "stripeLink",
};

export const userGradeField = {
  fieldType: "radio",
  label: "Grade de l'utilisateur",
  name: "grade",
  options: userGradeOptions,
  valueKey: "value",
  labelKey: "label",
};

export const gradeAllowedOptionsField = {
  fieldType: "checkbox",
  label: "Options de l'utilisateur",
  name: "managerAllowedOptions",
  options: gradePermissionOptions,
  valueKey: "id",
  labelKey: "label",
};

// managerAllowedOptions

export const userNameField = {
  ...genericFullnameTypeAndName,
  label: "Nom Complet de l'utilisateur",
  Ph: "Ex: George Brassens",
};

export const userEmailField = {
  ...genericEmailTypeAndName,
  label: "Email de l'utilisateur",
};

export const userPasswordField = {
  ...genericPasswordTypeAndName,
  label: "Mot de passe de l'utilisateur",
};

export const trainerName = {
  ...genericFullnameTypeAndName,
  label: "Nom Complet du formateur",
};

export const trainerPicture = {
  ...genericPictureTypeAndName,
  label: "Photo du formateur",
};

export const trainerEmail = {
  ...genericEmailTypeAndName,
  label: "Email du formateur",
};

export const trainingTitle = {
  ...genericTitleTypeAndName,
  label: "Titre",
};

export const trainingSubTitle = {
  ...genericTitleTypeAndName,
  label: "Sous-titre",
  name: "subTitle",
};

export const trainingDuration = {
  fieldType: "number",
  ph: "",
  label: "Duree de la formation(en minutes)",
  name: "duration",
};

export const trainingDesc = {
  label: "Description",
  fieldType: "editor",
  name: "description",
  additionalOptions: [
    ["fontColor", "hiliteColor", "textStyle"],
    ["table", "link", "image"],
  ],
};

export const trainingMiniature = {
  ...genericPictureTypeAndName,
  label: "Miniature",
  name: "miniature",
  accept: allImageAccept,
};

export const trainingVideo = {
  ...genericPictureTypeAndName,
  label: "Video",
  name: "trainingVideo",
  accept: allVideoAccept,
};
export const trainingRessources = {
  ...genericPictureTypeAndName,
  label: "Autres ressources",
  name: "trainingRessources",
  accept: allZipAccept,
};

export const trainingMentorId = {
  fieldType: "selectWithApi",
  label: "Formateur",
  name: "mentorId",
  valueKey: "_id",
  labelKey: "fullname",
  chooseLabelKey: "Choisir formateur",
  apiUrl: allTrainerUrl,
};

export const trainingIsFree = {
  fieldType: "select",
  label: "La formation est elle gratuite ?",
  name: "isFree",
  valueKey: "value",
  labelKey: "label",
  chooseLabelKey: "Choisir",
  options: yesOrNoOptions,
};

export const trainingStudentsC = {
  fieldType: "number",
  ph: "Ex: 100",
  label: "Nombres d'etudiant",
  name: "studentsCount",
};

export const trainingLevel = {
  fieldType: "select",
  label: "Niveau",
  name: "level",
  valueKey: "value",
  labelKey: "label",
  chooseLabelKey: "Choisir",
  options: trainingLevelOptions,
};

export const trainingTags = {
  fieldType: "multipleText",
  label: "Les tags(Ex: Publicite,Site Web, etc...)",
  name: "tags",
  newDataBtn: "Ajouter un nouveau tag",
  newDataPh: "Nouveau tag",
};

export const quizzQuestionField = {
  fieldType: "text",
  ph: "Quest ce que Shopify ?",
  label: "La question",
  name: "question",
};

export const quizzRightAnswerField = {
  fieldType: "number",
  ph: "1,2,3...",
  label: "Le numero de la bonne reponse",
  name: "rightAnswer",
};

export const quizzSuggestedAnswerField = {
  fieldType: "multipleText",
  label: "Les reponses possibles",
  name: "suggestedAnswers",
  newDataBtn: "Ajouter un nouvelle proposition de reponse",
  newDataPh: "Nouvelle reponse",
};

export const categoryLabelLevel = {
  fieldType: "text",
  label: "Nom de la categorie",
  ph: "Ex: Cosmetique,etc...",
  name: "label",
};

export const categoryTypegLevel = {
  fieldType: "select",
  label: "Type de categorie",
  name: "type",
  valueKey: "value",
  labelKey: "label",
  chooseLabelKey: "Choisir",
  options: categoryOptions,
};
