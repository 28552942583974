import { Form, FormikProvider, useFormik } from "formik";
import {
  productsFields,
  upProductsFields,
} from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";
import {
  ProductValidation,
  UpProductValidation,
} from "../../Config/formFields/fieldsValidations";
import dayjs from "dayjs";

export function AddorUpdateProductForm({ refetch, product }) {
  const formik = useFormik({
    initialValues: {
      name: product?.name ?? "",
      category: product?.category ?? "",
      productNumber: product?.productNumber ?? "",
      supplierPrice: product?.supplierPrice ?? "",
      price: product?.price ?? "",
      description: product?.description ?? "",
      supplierLink: product?.supplierLink ?? "",
      competitorLink: product?.competitorLink ?? "",
      competitorPubLink: product?.competitorPubLink ?? "",
      picture1: "",
      picture2: "",
      picture3: "",
      picture4: "",
      pictures: [],
      markets: product?.markets ?? [],
      publicationDate:
        dayjs(product?.publicationDate).format("YYYY-MM-DDTHH:mm") ?? "",
      isVisible: JSON.stringify(product?.isVisible) ?? "",
    },
    validateOnMount: true,
    validationSchema: product ? UpProductValidation : ProductValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formData = new FormData();
    formData.append("category", formvalues.category);
    formData.append("name", formvalues.name);
    formData.append("price", formvalues.price);
    formData.append("description", formvalues.description);
    formData.append("supplierPrice", formvalues.supplierPrice);
    formData.append("supplierLink", formvalues.supplierLink);

    formData.append("markets", formvalues.markets);
    formData.append("productNumber", formvalues.productNumber);

    formData.append(
      "publicationDate",
      dayjs(formvalues.publicationDate).valueOf()
    );

    if (formvalues.competitorLink) {
      formData.append("competitorLink", formvalues.competitorLink);
    }

    if (formvalues.competitorPubLink) {
      formData.append("competitorPubLink", formvalues.competitorPubLink);
    }

    if (formvalues.isVisible) {
      formData.append("isVisible", JSON.parse(formvalues.isVisible));
    }

    if (formvalues.pictures) {
      let mixImg = formvalues.pictures;

      for (let j = 0; j < mixImg.length; j++) {
        const element = mixImg[j];
        formData.append("productPictures", element);
      }
    }

    let request = product
      ? axiosInstance.put("/product/update/" + product._id, formData)
      : axiosInstance.post("/product/add", formData);

    request
      .then((res) => successToast("Produit ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {(product ? upProductsFields : productsFields).map((item, i) => (
          <FormFieldProvider {...item} key={"product field lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
