import { Form, FormikProvider, useFormik } from "formik";
import { addLPTypeFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";
import { LeadPageTypeValidation } from "../../Config/formFields/fieldsValidations";

export function AddorUpdateLPType({ refetch, leadPageType }) {
  const formik = useFormik({
    initialValues: {
      label: leadPageType?.label ?? "",
    },
    validateOnMount: true,
    validationSchema: LeadPageTypeValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    let request = leadPageType
      ? axiosInstance.put(
          "/leadPage/type/update/" + leadPageType._id,
          formvalues
        )
      : axiosInstance.post("/leadPage/type/add", formvalues);

    request
      .then((res) => successToast("Section ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {addLPTypeFields.map((item, i) => (
          <FormFieldProvider {...item} key={"section fields lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
